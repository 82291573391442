import { useState } from 'react'
import { Button, Flex, Modal, Row, Table } from 'antd'
import * as XLSX from 'xlsx'
import dayjs from 'dayjs'
import utils from '../../utils'
import dashboardApi from '../../api/dashboard.api'
import '../../styles/home.scss'

const ExcelUploadModal = ({ open, setOpen, getShuFarmInfo }) => {
  const [uploadExcelName, setUploadExcelName] = useState('') // 업로드한 엑셀 파일명
  const [uploadExcelData, setUploadExcelData] = useState([]) // 업로드한 엑셀 데이터
  const [validation, setValidation] = useState(false)
  const [uploadExcelDataError, setUploadExcelDataError] = useState([])
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '신청 아이디',
      width: 90,
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: '실패 사유',
      dataIndex: 'error',
      key: 'error',
      align: 'center',
    },
  ]

  /**
   * 업로드된 엑셀 읽기
   */
  const readExcelData = async (e) => {
    if (e.target.value !== '') {
      let reader = new FileReader()
      reader.onload = () => {
        let fileData = reader.result
        let wb = XLSX.read(fileData, {
          type: 'binary',
          cellDates: true,
          dateNF: 'YYYY-MM-DD',
        })
        let sheetNameList = wb.SheetNames
        let firstSheet = wb.Sheets[sheetNameList[0]]

        let data = XLSX.utils.sheet_to_json(firstSheet, { raw: false })
        console.log('data = ', data)
        setUploadExcelData(data)
        setUploadExcelName(e.target.files[0]?.name)
      }
      reader.readAsBinaryString(e.target.files[0])
    } else {
      setUploadExcelData([])
      setUploadExcelName('')
    }
  }

  const handleExcelUpload = async () => {
    setLoading(true)
    // step 1. 엑셀 데이터 유효성 검사 및 포맷팅
    const validationResult = await handleExcelValidation()
    if (validationResult.isValid) {
      const dataFormatting = await trackingNumExcelFormat(
        validationResult.dataKeys,
      )
      try {
        const res = await dashboardApi.registerShuFarmDeliveryInfo({
          addressList: dataFormatting,
        })
        if (res.data.code === 200) {
          alert('해당 내용이 성공적으로 반영되었습니다.')
          getShuFarmInfo()
          const timer = setTimeout(() => {
            handleExcelUploadCancel('close')
          }, 1000)
          return () => {
            clearTimeout(timer)
          }
        } else if (res.data.code === 406) {
          alert(res.data.msg)
          getShuFarmInfo()
          const timer = setTimeout(() => {
            handleExcelUploadCancel('close')
          }, 1000)
          return () => {
            clearTimeout(timer)
          }
        } else {
          alert(
            '해당 내용을 수정하는데 실패했습니다. 관리자에게 문의하시길 바랍니다.',
          )
          const timer = setTimeout(() => {
            setUploadExcelData([])
            let excelInput = document.getElementById('excelFile')
            excelInput.value = ''
            setUploadExcelName('')
            setValidation(false)
          }, 1000)
          return () => {
            clearTimeout(timer)
          }
        }
      } catch (e) {
        console.log('handleExcelUpload :: e = ', e)
      } finally {
        setLoading(false)
      }
    }
    setLoading(false)
  }

  /**
   * 업로드된 송장 엑셀 유효성 체크
   */
  const handleExcelValidation = async () => {
    let isValid = false
    let dataKeys = []
    try {
      if (uploadExcelData?.length > 1) {
        let nonExistentRowList = []
        let orderIdList = []
        let duplicateOrderIdList = []
        dataKeys = uploadExcelData.shift()
        for (const data of uploadExcelData) {
          let validValueList = []
          Object.keys(dataKeys).forEach((key) => {
            switch (key) {
              case '신청 아이디':
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                  if (orderIdList.includes(data[key])) {
                    duplicateOrderIdList.push(data[key])
                    validValueList.push(true)
                  } else {
                    orderIdList.push(data[key])
                    validValueList.push(true)
                  }
                } else {
                  validValueList.push(false)
                }
                break
              case '신청자 아이디':
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                  validValueList.push(true)
                } else {
                  validValueList.push(false)
                }
                break
              case '배송사':
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                  if (
                    Object.values(utils.matchingDeliveryCompany).includes(
                      data[key],
                    )
                  ) {
                    validValueList.push(true)
                  } else {
                    validValueList.push(false)
                  }
                } else {
                  validValueList.push(false)
                }
                break
              case '발송일':
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                  if (typeof data[key] !== 'string') {
                    validValueList.push(false)
                  } else {
                    if (/\d{4}-\d{2}-\d{2}/.test(data[key])) {
                      validValueList.push(true)
                    } else {
                      validValueList.push(false)
                    }
                  }
                }
                break
            }
          })
          if (
            !Object.prototype.hasOwnProperty.call(data, '배송사') ||
            !Object.prototype.hasOwnProperty.call(data, '송장번호') ||
            !Object.prototype.hasOwnProperty.call(data, '신청자 아이디')
          ) {
            nonExistentRowList.push(data.__rowNum__ + 1)
          } else {
            if (!validValueList.every((result) => result)) {
              nonExistentRowList.push(data.__rowNum__ + 1)
            }
          }
        }
        if (nonExistentRowList?.length !== 0) {
          throw `${nonExistentRowList.join(
            '행, ',
          )}행의 값이 비어있거나 올바른 형식이 아닙니다.\n올바른 파일을 다시 업로드해주세요`
        } else if (duplicateOrderIdList?.length !== 0) {
          throw `신청 아이디가 ${duplicateOrderIdList.join(
            ', ',
          )}인 행이 중복되어있습니다.\n확인 후 올바른 파일을 다시 업로드해주세요`
        } else {
          const res = await dashboardApi.isPossibleUploadTrackingNumber({
            idList: orderIdList,
          })
          if (res.data.code === 200) {
            if (res.data.result === 0) {
              setLoading(false)
              alert('엑셀 유효성검사가 완료되었습니다.')
              setValidation(true)
              isValid = true
            } else {
              throw '신청건들 중 배송상태가 배송준비중이거나 배송중이 아닌 항목들이 존재합니다.\n확인 후 올바른 파일을 다시 업로드해주세요'
            }
          } else {
            throw '해당 신청건들에 대한 베송 상태를 확인하는데 실패하였습니다.\n새로고침 후 다시 업로드 진행해주시길 바랍니다.'
          }
        }
      } else {
        throw '업로드할 데이터가 없습니다.\n데이터를 입력하거나 템플릿 수정 후 다시 업로드해주세요'
      }
    } catch (e) {
      setLoading(false)
      console.log('handleExcelValidation validation error', e)
      alert(e)
      setTimeout(() => {
        setUploadExcelData([])
        let excelInput = document.getElementById('excelFile')
        excelInput.value = ''
        setUploadExcelName('')
        setValidation(false)
      }, 1000)
    } finally {
      setLoading(false)
      // eslint-disable-next-line no-unsafe-finally
      return { isValid: isValid, dataKeys: dataKeys }
    }
  }

  /**
   * 송장 업로드 양식으로 데이터 바인딩
   */
  const trackingNumExcelFormat = async (uploadExcelDataHeader) => {
    const dataFormatting = uploadExcelData.map((excelData) => {
      let returnValue = {}
      Object.keys(uploadExcelDataHeader).forEach((key) => {
        if (key === '배송사') {
          returnValue[uploadExcelDataHeader[key]] = Object.keys(
            utils.matchingDeliveryCompany,
          ).find(
            (companyKey) =>
              utils.matchingDeliveryCompany[companyKey] === excelData[key],
          )
        } else if (key === '발송일') {
          if (excelData[key]) {
            returnValue[uploadExcelDataHeader[key]] = dayjs(excelData[key])
          } else {
            returnValue[uploadExcelDataHeader[key]] = dayjs()
          }
        } else {
          returnValue[uploadExcelDataHeader[key]] = excelData[key]
        }
      })
      returnValue['delivery_status'] = 'delivery_shipping'
      return returnValue
    })
    return dataFormatting
  }

  /**
   * 송장엑셀업로드 Modal click cancel handler
   */
  const handleExcelUploadCancel = (type) => {
    setUploadExcelData([])
    setUploadExcelName('')
    setValidation(false)
    setUploadExcelDataError([])
    if (type === 'close') {
      setOpen(false)
    }
  }

  return (
    <Modal
      className={'shufarm-delivery-excel-upload-modal'}
      title="엑셀 일괄 발송처리"
      open={open}
      onCancel={() => handleExcelUploadCancel('close')}
      footer={null}
    >
      <Flex vertical gap={'0.5em'}>
        {!uploadExcelDataError?.length ? (
          <>
            <b>엑셀 파일을 업로드 하세요</b>
            <Flex className={'excel-file-upload-div'}>
              <Flex
                align={'center'}
                gap={'0.6em'}
                className={'excel-file-upload-each'}
              >
                <p className={`upload-title`}>파일 업로드</p>
              </Flex>
              <Flex
                align={'center'}
                gap={'0.6em'}
                className={'excel-file-upload-each'}
              >
                <div className={`upload-input`}>
                  {uploadExcelName?.length
                    ? uploadExcelName
                    : '선택된 파일 없음'}
                </div>
                <Button
                  className={'excel-file-select-btn'}
                  onClick={() => {
                    let excelInput = document.getElementById('excelFile')
                    if (excelInput) {
                      excelInput.click()
                    }
                  }}
                >
                  파일 선택
                </Button>
                <Button
                  className={'excel-file-upload-btn'}
                  onClick={handleExcelUpload}
                  loading={loading}
                >
                  등록하기
                </Button>
              </Flex>
            </Flex>
            <input
              style={{ display: 'none' }}
              disabled={validation}
              type={'file'}
              id="excelFile"
              onChange={readExcelData}
            />
            <p className={'excel-description'}>
              * 다운로드 받은 엑셀 파일에서 발송처리 할 데이터 작성 후 등록해
              주세요.
            </p>
            <p className={`excel-description`}>
              * 필수정보(신청 아이디, 신청자 아이디, 배송사, 송장번호)가 제대로
              입력되었는지 확인해주세요.
            </p>
            <p className={`excel-description`}>
              * 파일 형식은 XLSX 로 올려주세요.
            </p>
            <p className={`excel-description`}>
              * 배송정보가 기재된 엑셀파일 선택 후 ‘등록하기’를 눌러 배송처리를
              완료하세요.
            </p>
            <Button
              className={'excel-template-download'}
              href={`${process.env.REACT_APP_HOST}/assets/files/ShuFarm_TrackingNumber_Template.xlsx`}
            >
              <p className={`excel-description`}>
                엑셀 일괄 발송처리 양식 다운로드
              </p>
            </Button>
          </>
        ) : (
          <>
            <p className={'excel-description'}>
              * 1행, 2행은 삭제하시면 안됩니다.
            </p>
            <p className={'excel-description'}>
              * 행의 값이 비어있거나 올바른 형식이 아닙니다.
            </p>
            <p className={'excel-description'}>
              * 확인 후 다시 업로드해주세요.
            </p>
            <Table
              className={'commission-table'}
              columns={columns}
              dataSource={uploadExcelDataError}
              loading={loading}
              bordered
              size="small"
              rowKey="month"
              rowClassName="coupon-table-row"
              scroll={{ y: 250 }}
              pagination={false}
            />
            <Row
              align={`middle`}
              justify={`space-between`}
              style={{
                marginTop: '1em',
              }}
            >
              <Button
                className={'excel-template-download'}
                href={`${process.env.REACT_APP_HOST}/assets/files/ShuFarm_TrackingNumber_Template.xlsx`}
                style={{ marginTop: 0 }}
              >
                <p className={'excel-description'}>
                  엑셀 일괄 발송처리 양식 다운로드
                </p>
              </Button>
              <Button
                className={`excel-file-upload-btn`}
                onClick={handleExcelUploadCancel}
                style={{
                  marginLeft: 'auto',
                }}
              >
                다시 등록하기
              </Button>
            </Row>
          </>
        )}
      </Flex>
    </Modal>
  )
}

export default ExcelUploadModal
