import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`

const login = async (param) => {
  const result = await axios.post(`${baseUrl}/user/login/admin`, param)
  return result
}

const logout = async (param) => {
  const result = await axios.post(`${baseUrl}/user/logout/admin`, param)
  return result
}

const loginApi = {
  login,
  logout,
}

export default loginApi
