import { Flex } from 'antd'
import '../../styles/layout.scss'

const ContentHeader = ({ title, isBorder }) => {
  return (
    <Flex
      align={'center'}
      className={`content-header ${isBorder ? 'add-border-bottom' : ''}`}
    >
      <h1>{title}</h1>
    </Flex>
  )
}

export default ContentHeader
