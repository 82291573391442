import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { userState } from '../../store/user'
import { Flex, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import PeriodSearchFilter from '../shared/PeriodSearchFilter'
import dashboardApi from '../../api/dashboard.api'
import '../../styles/home.scss'

const UserDashboard = () => {
  const [user, setUser] = useRecoilState(userState)
  const [isLoading, setIsLoading] = useState(true)
  const [dateType, setDateType] = useState('today')
  const [searchOptions, setSearchOptions] = useState({
    startDate: dayjs().startOf('day'),
    endDate: dayjs().endOf('day'),
  })
  const [userStatistics, setUserStatistics] = useState({
    visitCnt: 0,
    newCnt: 0,
    existingCnt: 0,
  })

  useEffect(() => {
    if (searchOptions?.startDate && searchOptions?.endDate) {
      getUserStatistics()
    }
  }, [searchOptions])

  const getUserStatistics = async () => {
    setIsLoading(true)
    try {
      const param = {
        startDate: dayjs(searchOptions.startDate).format('YYYYMMDD'),
        endDate: dayjs(searchOptions.endDate).format('YYYYMMDD'),
        userId: user.id,
      }
      const res = await dashboardApi.getUserStatistics(param)
      if (res.data.code === 200) {
        setUserStatistics({
          visitCnt: res.data.result.visit_count,
          newCnt: res.data.result.new_count,
          existingCnt: res.data.result.existing_count,
        })
      } else {
        setUserStatistics({
          visitCnt: 0,
          newCnt: 0,
          existingCnt: 0,
        })
      }
    } catch (e) {
      console.log('UserDashboard :: getUserStatistics error = ', e)
      setUserStatistics({
        visitCnt: 0,
        newCnt: 0,
        existingCnt: 0,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const listTitle = (key) => {
    if (key === 'visitCnt') {
      return '방문자수'
    } else if (key === 'newCnt') {
      return '신규회원수'
    } else if (key === 'existingCnt') {
      return '기존회원수'
    }
  }

  return (
    <Flex
      vertical
      gap={15}
      className={`home-dashboard-component width-50 ${isLoading ? 'container-disable' : ''}`}
    >
      {isLoading && (
        <Spin
          className={'home-dashboard-loading'}
          indicator={<LoadingOutlined spin />}
          size={'large'}
        />
      )}
      <Flex align={'center'} className={'home-dashboard-title'}>
        <h2>가입/유입 통계</h2>
      </Flex>
      <PeriodSearchFilter
        dateType={dateType}
        setDateType={setDateType}
        searchOptions={searchOptions}
        setSearchOptions={setSearchOptions}
      />
      <Flex justify={'space-between'} align={'center'} flex={1} gap={10}>
        {Object.keys(userStatistics).map((key, idx) => (
          <Flex
            vertical
            justify={'center'}
            align={'center'}
            gap={5}
            key={`user_list_${idx}`}
            className={'user-statistics-div'}
          >
            <p className={'user-statistics-title'}>{listTitle(key)}</p>
            <Flex align={'center'} className={'user-statistics-info-wrap'}>
              <p>{userStatistics[key]?.toLocaleString()}</p>
              <p>명</p>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default UserDashboard
