import { Flex } from 'antd'
import { Column } from '@ant-design/plots'
import '../../styles/home.scss'

const BenefitGoldDashboard = ({ goldInfo }) => {
  const config = {
    data: [],
    height: 300,
    paddingTop: 20,
    xField: 'key',
    yField: 'value',
    axis: {
      x: {
        labelFill: '#1A1A1A',
        labelFontFamily: 'Pretendard',
        labelFontWeight: 500,
      },
      y: {
        labelFill: '#1A1A1A',
        labelFontFamily: 'Pretendard',
        labelFontWeight: 500,
      },
    },
    label: {
      text: (d) => `${d.value.toLocaleString()}명`,
      textBaseline: 'bottom',
    },
    tooltip: (d) => ({
      name: d.key,
      value: `${d.value.toLocaleString()}명`,
    }),
    style: {
      radiusTopLeft: 5,
      radiusTopRight: 5,
      fill: '#117FFA',
      maxWidth: 30,
    },
  }

  const listTitle = (key) => {
    if (key === 'totalCnt') {
      return '전체'
    } else if (key === 'notAppliedCnt') {
      return '미신청'
    } else if (key === 'appliedCnt') {
      return '신청'
    } else if (key === 'underwayCnt') {
      return '진행중'
    } else if (key === 'completeCnt') {
      return '완료'
    }
  }

  return (
    <Flex
      vertical
      justify={'space-between'}
      gap={20}
      style={{ height: '100%' }}
    >
      {/*금나와라 뚝딱*/}
      <Flex vertical gap={10}>
        <p className={'benefit-content-title'}>
          금나와라 뚝딱 (총 {goldInfo?.totalCnt?.toLocaleString()}명)
        </p>
        <Column
          {...config}
          data={goldInfo?.statisticsList}
          scale={{
            y: {
              domainMax: goldInfo?.statisticsList?.every(
                (info) => info.value === 0,
              )
                ? 200
                : null,
            },
          }}
          className={'benefit-chart-wrap'}
        />
      </Flex>
      {/*신청내역*/}
      <Flex vertical gap={10}>
        <p className={'benefit-content-title'}>
          금나와라 뚝딱 신청내역 (총{' '}
          {goldInfo?.applicationList?.totalCnt?.toLocaleString()}명)
        </p>
        <Flex
          justify={'space-around'}
          align={'center'}
          className={'benefit-application-list'}
        >
          {Object.keys(goldInfo?.applicationList).map((key, idx) => (
            <Flex
              vertical
              align={'center'}
              gap={5}
              key={`benefit-application-list-${idx}`}
            >
              <p className={'benefit-application-list-title'}>
                {listTitle(key)}
              </p>
              <Flex
                align={'center'}
                className={'benefit-application-list-info-wrap'}
              >
                <p>{goldInfo?.applicationList[key]}</p>
                <p>명</p>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BenefitGoldDashboard
