import { Flex, Layout, Menu } from 'antd'
import { useRecoilState } from 'recoil'
import { menuState } from '../../store/menu'
import '../../styles/layout.scss'
import React, { useEffect, useState } from 'react'
import { userState } from '../../store/user'
import Swal from 'sweetalert2'
import loginApi from '../../api/login.api'
import { tokenState } from '../../store/token'
import PageRouting from './PageRouting'
import { useLocation, useNavigate } from 'react-router-dom'

const { Header, Sider, Content } = Layout

const PageLayout = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [menu, setMenu] = useRecoilState(menuState)
  const [user, setUser] = useRecoilState(userState)
  const [token, setToken] = useRecoilState(tokenState)
  const [openKey, setOpenKey] = useState([])
  const [selectKey, setSelectKey] = useState([])

  const menuItems = menu?.map((menuItem, index) => {
    return {
      key: menuItem.unique_id,
      label: menuItem.name,
      children: menuItem.subMenuList?.map((subMenu, subIndex) => {
        return {
          key: subMenu.url ? subMenu.url : subMenu.unique_id,
          label: subMenu.name,
        }
      }),
    }
  })

  useEffect(() => {
    if (pathname !== '/') {
      const findMenu = menu?.find(
        (mainMenu) =>
          mainMenu.subMenuList?.findIndex(
            (subMenu) => subMenu.url === pathname,
          ) > -1,
      )
      if (findMenu) {
        setOpenKey([pathname, findMenu?.unique_id])
        setSelectKey([pathname])
      } else {
        setOpenKey([])
        setSelectKey([])
      }
    } else {
      setOpenKey([])
      setSelectKey([])
    }
  }, [pathname])

  const handleMenuOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => openKey.indexOf(key) === -1)
    if (currentOpenKey !== undefined) {
      setOpenKey([currentOpenKey])
    } else {
      setOpenKey(openKeys)
    }
  }

  const handleMenuClick = ({ key }) => {
    if (key.includes('/')) {
      navigate(key)
    }
  }

  const handleLogout = () => {
    Swal.fire({
      customClass: { popup: 'custom-alert' },
      text: '로그아웃하시겠습니까?',
      confirmButtonText: '확인',
      confirmButtonColor: '#117FFA',
      showCancelButton: true,
      cancelButtonText: '취소',
      cancelButtonColor: '#FFFFFF',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // 로그아웃 처리
        let msg = '로그아웃에 실패하였습니다. 다시 시도해주세요.'
        const param = {
          id: user?.id,
        }
        const res = await loginApi.logout(param)
        if (res.data.code === 200) {
          msg = '정상적으로 로그아웃 되었습니다.'
        }
        Swal.fire({
          customClass: { popup: 'custom-alert' },
          text: msg,
          confirmButtonText: '확인',
          confirmButtonColor: '#117FFA',
        }).then((sndResult) => {
          if (res.data.code === 200) {
            setToken({
              accessToken: null,
              refreshToken: null,
            })
            setUser({
              id: 0,
              name: '',
            })
            setMenu([])
            navigate('/')
          }
        })
      }
    })
  }

  return (
    <Layout className={'layout'}>
      <Header className={'header'}>
        {/*로고 - 슈가코치 SALUS*/}
        <Flex
          align={'center'}
          gap={8}
          className={'logo-wrap'}
          onClick={() => navigate('/')}
        >
          <img
            src={`${process.env.REACT_APP_HOST}/assets/sugar-logo.png`}
            className={'logo-img'}
          />
          <p className={'logo-title'}>
            슈가코치 <span>SALUS</span>
          </p>
        </Flex>
        <Flex align={'center'} gap={15}>
          <p className={'user-name'}>{user?.name} 님</p>
          <p onClick={handleLogout} className={'logout'}>
            로그아웃
          </p>
        </Flex>
      </Header>
      <Layout>
        <Sider width={200}>
          <Menu
            className={'menu-wrap'}
            mode="inline"
            items={menuItems}
            openKeys={openKey}
            onOpenChange={handleMenuOpenChange}
            selectedKeys={selectKey}
            onClick={handleMenuClick}
            inlineIndent={12}
          />
        </Sider>
        <Content>
          <PageRouting />
        </Content>
      </Layout>
    </Layout>
  )
}

export default PageLayout
