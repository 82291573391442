export const CustomAntdTheme = {
  token: {
    colorPrimary: '#117FFA',
  },
  components: {
    Layout: {
      headerBg: '#3E4C5E',
      headerHeight: 'auto',
      headerPadding: '14px 6vw 14px 25px',
      siderBg: '#3E4C5E',
    },
    Menu: {
      itemBg: '#3E4C5E',
      itemColor: '#FFFFFF',
      itemBorderRadius: 0,
      itemSelectedBg: 'transparent',
      itemHoverBg: 'transparent',
      itemHoverColor: '#117FFA',
      subMenuItemBg: '#313E50',
      itemMarginInline: 25,
      itemMarginBlock: 0,
      itemHeight: 70,
      iconSize: 20,
    },
  },
}

// PeriodSearchFilter.jsx
export const CustomDateButtonRadioTheme = {
  token: {
    borderRadius: 0,
    fontFamily: 'Pretendard',
  },
  components: {
    Radio: {
      buttonColor: '#979797',
      wrapperMarginInlineEnd: 0,
    },
  },
}

// BenefitShuFarmDashboard.jsx > 슈팜 비타민 신청내역 버튼
export const CustomExcelButtonTheme = {
  components: {
    Button: {
      defaultActiveBorderColor: '#389e0d',
      defaultActiveColor: '#389e0d',
      defaultHoverBorderColor: '#73d13d',
      defaultHoverColor: '#73d13d',
      paddingBlock: 0,
    },
  },
}
