import { useState } from 'react'
import { Button, ConfigProvider, Flex } from 'antd'
import { Column } from '@ant-design/plots'
import { CustomExcelButtonTheme } from '../../styles/CustomAntdTheme'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import * as XLSX from 'xlsx'
import ExcelUploadModal from './ExcelUploadModal'
import utils from '../../utils'
import dashboardApi from '../../api/dashboard.api'
import '../../styles/home.scss'

const BenefitShuFarmDashboard = ({
  shuFarmInfo,
  shuFarmApplicationInfo,
  getShuFarmInfo,
}) => {
  const [uploadExcelModalOpen, setUploadExcelModalOpen] = useState(false)

  const config = {
    data: [],
    height: 300,
    paddingTop: 20,
    xField: 'key',
    yField: 'value',
    axis: {
      x: {
        labelFill: '#1A1A1A',
        labelFontFamily: 'Pretendard',
        labelFontWeight: 500,
      },
      y: {
        labelFill: '#1A1A1A',
        labelFontFamily: 'Pretendard',
        labelFontWeight: 500,
      },
    },
    label: {
      text: (d) => `${d.value.toLocaleString()}명`,
      textBaseline: 'bottom',
    },
    tooltip: (d) => ({
      name: d.key,
      value: `${d.value.toLocaleString()}명`,
    }),
    style: {
      radiusTopLeft: 5,
      radiusTopRight: 5,
      fill: '#1CA717',
      maxWidth: 30,
    },
  }

  const listTitle = (key) => {
    if (key === 'totalCnt') {
      return '전체'
    } else if (key === 'notAppliedCnt') {
      return '미신청'
    } else if (key === 'appliedCnt') {
      return '신청'
    } else if (key === 'deliveryReadyCnt') {
      return '준비중'
    } else if (key === 'underwayCnt') {
      return '진행중'
    } else if (key === 'deliveryShippingCnt') {
      return '배송중'
    } else if (key === 'completeCnt') {
      return '완료'
    }
  }

  /**
   * 유형별 슈팜 신청내역 조회
   * @param type 전체-total / 신청-applied / (배송)준비중-deliveryReady
   * @returns {Promise<void>}
   */
  const readShuFarmListByType = async (type) => {
    try {
      const param = {
        type: type,
      }
      const res = await dashboardApi.getShuFarmListByType(param)
      if (res.data.code === 200) {
        return res.data.result
      } else {
        throw res.data.code
      }
    } catch (e) {
      console.log(
        'BenefitShuFarmDashboard :: readShuFarmListByType error = ',
        e,
      )
      Swal.showValidationMessage(`
        데이터를 조회하는데 실패하였습니다.<br />새로고침 후 다시 시도해주세요.
      `)
    }
  }

  /**
   * 슈팜 비타민 신청내역 발주확인 처리하기
   */
  const confirmShuFarmDeliveryReady = async () => {
    try {
      const res = await dashboardApi.confirmShuFarmDeliveryReady()
      if (res.data.code === 200) {
        return res.data.result
      } else {
        throw res.data.code
      }
    } catch (e) {
      console.log(
        'BenefitShuFarmDashboard :: confirmShuFarmDeliveryReady error = ',
        e,
      )
      Swal.showValidationMessage(`
        발주 확인 처리하는데 실패하였습니다.<br />새로고침 후 다시 시도해주세요.
      `)
    }
  }

  /**
   * 엑셀로 내려받기 버튼 클릭 이벤트 핸들러
   */
  const handleExcelDownloadClick = () => {
    Swal.fire({
      customClass: { popup: 'custom-close-alert' },
      title: '엑셀로 내려받기',
      showCloseButton: true,
      input: 'radio',
      inputOptions: {
        total: '전체',
        applied: '신청',
        deliveryReady: '준비중',
      },
      inputValue: 'total',
      confirmButtonText: '확인',
      confirmButtonColor: '#117FFA',
      showLoaderOnConfirm: true,
      preConfirm: readShuFarmListByType,
    }).then((result) => {
      if (result.value) {
        // 엑셀 다운로드 처리
        createExcelDownloadData(result.value.addressList)
      }
    })
  }

  /**
   * 슈팜 비타민 신청 내역 엑셀로 변환
   */
  const createExcelDownloadData = (dataList) => {
    try {
      const excelData = dataList.map((row, idx) => {
        return {
          index: idx + 1,
          name: row.name,
          phone: row.phone,
          postal_code: row.postal_code,
          address: row.address,
          address_detail: row.address_detail,
          address_request: row.address_request,
          delivery_company_name: row.delivery_company_name,
          tracking_number: row.tracking_number,
          ship_date: row.ship_date
            ? dayjs(row.ship_date).format('YYYY-MM-DD hh:mm:ss')
            : null,
          goodsflow_service_id: row.goodsflow_service_id,
          created_at: row.created_at
            ? dayjs(row.created_at).format('YYYY-MM-DD hh:mm:ss')
            : null,
          id: row.id,
          sugarcoach_id: row.sugarcoach_id,
        }
      })
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(excelData)
      Object.keys(excelData[0]).forEach((key, idx) => {
        const cellAdd = XLSX.utils.encode_cell({ c: idx, r: 0 })
        ws[cellAdd].v = utils.shuFarmAddressExcelKey[key]
      })
      ws['!rows'] = Array(dataList.length + 1)
        .fill()
        .map(() => ({ hpt: 24 }))

      XLSX.utils.book_append_sheet(wb, ws, '슈팜 비타민 신청내역')
      XLSX.writeFile(
        wb,
        `${dayjs().format('YYYYMMDD')}_슈팜비타민신청내역.xlsx`,
        { cellStyles: true },
      )
    } catch (e) {
      console.log('createExcelDownloadData :: error = ', e)
    }
  }

  /**
   * 발주 확인하기 버튼 클릭 이벤트 핸들러
   */
  const handleDeliveryConfirmClick = () => {
    Swal.fire({
      customClass: { popup: 'custom-close-alert' },
      title: '발주 확인하기',
      html: `현재 비타민 신청건은 ${shuFarmApplicationInfo?.appliedCnt.toLocaleString()}건 입니다.<br />해당 건 전부 발주 확인하시겠습니까?`,
      confirmButtonText: '확인',
      confirmButtonColor: '#117FFA',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonText: '취소',
      cancelButtonColor: '#FFFFFF',
      preConfirm: confirmShuFarmDeliveryReady,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          customClass: { popup: 'custom-alert' },
          icon: 'success',
          text: `총 ${result.value}건 발주 처리되었습니다.`,
          confirmButtonText: '확인',
          confirmButtonColor: '#117FFA',
        }).then(() => {
          getShuFarmInfo()
        })
      }
    })
  }

  return (
    <Flex vertical gap={20}>
      {/*슈팜*/}
      <Flex vertical gap={10}>
        <p className={'benefit-content-title'}>
          슈팜 (총 {shuFarmInfo?.totalCnt?.toLocaleString()}명)
        </p>
        <Column
          {...config}
          data={shuFarmInfo?.statisticsList}
          scale={{
            y: {
              domainMax: shuFarmInfo?.statisticsList?.every(
                (info) => info.value === 0,
              )
                ? 200
                : null,
            },
          }}
          className={'benefit-chart-wrap'}
        />
      </Flex>
      {/*신청내역*/}
      <Flex vertical gap={10}>
        <Flex justify={'space-between'} align={'center'} gap={5} wrap>
          <p className={'benefit-content-title'}>
            슈팜 비타민 신청내역 (총{' '}
            {shuFarmApplicationInfo?.totalCnt?.toLocaleString()}명)
          </p>
          <ConfigProvider theme={CustomExcelButtonTheme}>
            <Flex
              justify={'flex-end'}
              align={'center'}
              gap={5}
              className={'benefit-shufarm-button-list'}
            >
              <Button
                icon={
                  <img
                    style={{ height: 14.2, width: 14.5 }}
                    src={`${process.env.REACT_APP_HOST}/assets/excel-icon.png`}
                    alt={`엑셀이미지`}
                  />
                }
                onClick={handleExcelDownloadClick}
              />
              <Button
                className={'benefit-shufarm-button'}
                disabled={shuFarmApplicationInfo?.appliedCnt === 0}
                onClick={handleDeliveryConfirmClick}
              >
                발주확인
              </Button>
              <Button
                className={'benefit-shufarm-button'}
                onClick={() => setUploadExcelModalOpen(true)}
              >
                발송처리
              </Button>
            </Flex>
          </ConfigProvider>
        </Flex>
        <Flex
          justify={'space-around'}
          align={'center'}
          className={'benefit-application-list'}
        >
          {Object.keys(shuFarmApplicationInfo).map((key, idx) => (
            <Flex
              vertical
              align={'center'}
              gap={5}
              key={`benefit-application-list-${idx}`}
            >
              <p className={'benefit-application-list-title'}>
                {listTitle(key)}
              </p>
              <Flex
                align={'center'}
                className={'benefit-application-list-info-wrap'}
              >
                <p>{shuFarmApplicationInfo[key]}</p>
                <p>명</p>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
      {/*슈팜 비타민 신청내역 발송처리 Modal*/}
      {uploadExcelModalOpen && (
        <ExcelUploadModal
          open={uploadExcelModalOpen}
          setOpen={setUploadExcelModalOpen}
          getShuFarmInfo={getShuFarmInfo}
        />
      )}
    </Flex>
  )
}

export default BenefitShuFarmDashboard
