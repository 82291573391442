const shuFarmAddressExcelKey = {
  index: '번호',
  name: '수취인명',
  phone: '연락처',
  postal_code: '우편번호',
  address: '주소지',
  address_detail: '상세주소',
  address_request: '배송메모',
  delivery_company_name: '배송사',
  tracking_number: '송장번호',
  ship_date: '발송일',
  goodsflow_service_id: '굿스플로 서비스 아이디',
  created_at: '신청일',
  id: '신청 아이디',
  sugarcoach_id: '슈가코치 아이디',
}

const matchingDeliveryCompany = {
  'de.dhl': 'DHL',
  'jp.sagawa': 'Sagawa',
  'jp.yamato': 'Kuroneko Yamato',
  'jp.yuubin': 'Japan Post',
  'kr.chunilps': '천일택배',
  'kr.cjlogistics': 'CJ대한통운',
  'kr.cupost': 'CU 편의점택배',
  'kr.cvsnet': 'GS Postbox 택배',
  'kr.cway': 'CWAY (Woori Express)',
  'kr.daesin': '대신택배',
  'kr.epost': '우체국 택배',
  'kr.hanips': '한의사랑택배',
  'kr.hanjin': '한진택배',
  'kr.hdexp': '합동택배',
  'kr.homepick': '홈픽',
  'kr.honamlogis': '한서호남택배',
  'kr.ilyanglogis': '일양로지스',
  'kr.kdexp': '경동택배',
  'kr.kunyoung': '건영택배',
  'kr.logen': '로젠택배',
  'kr.lotte': '롯데택배',
  'kr.slx': 'SLX',
  'kr.swgexp': '성원글로벌카고',
  'nl.tnt': 'TNT',
  'un.upu.ems': 'EMS',
  'us.fedex': 'Fedex',
  'us.ups': 'UPS',
  'us.usps': 'USPS',
}

const utils = {
  shuFarmAddressExcelKey,
  matchingDeliveryCompany,
}

export default utils
