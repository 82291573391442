import { BrowserRouter } from 'react-router-dom'

import LoginPage from './pages/LoginPage/LoginPage'
import { useRecoilState } from 'recoil'
import { userState } from './store/user'
import { tokenState } from './store/token'
import PageLayout from './components/shared/PageLayout'

function App() {
  const [user, setUser] = useRecoilState(userState)
  const [token, setToken] = useRecoilState(tokenState)

  return (
    <BrowserRouter>
      {user && user?.id && token.accessToken && token.refreshToken ? (
        <PageLayout></PageLayout>
      ) : (
        <LoginPage></LoginPage>
      )}
    </BrowserRouter>
  )
}

export default App
