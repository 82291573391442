import React from 'react'
import { Flex } from 'antd'
import ContentHeader from '../../components/shared/ContentHeader'
import UserDashboard from '../../components/home/UserDashboard'
import InquiryDashboard from '../../components/home/InquiryDashboard'
import BenefitDashboard from '../../components/home/BenefitDashboard'
import '../../styles/home.scss'

const HomePage = () => {
  return (
    <>
      <ContentHeader title={'대시보드'} />
      <Flex vertical flex={1} gap={20} className={'home-div'}>
        {/*가입/유입 통계 && 미답변문의*/}
        <Flex gap={25}>
          <UserDashboard />
          <InquiryDashboard />
        </Flex>
        {/*혜택 관리*/}
        <BenefitDashboard />
      </Flex>
    </>
  )
}

export default HomePage
