import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { userState } from '../../store/user'
import { Flex, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dashboardApi from '../../api/dashboard.api'
import '../../styles/home.scss'

const InquiryDashboard = () => {
  const [user, setUser] = useRecoilState(userState)
  const [isLoading, setIsLoading] = useState(true)
  const [inquiryInfo, setInquiryInfo] = useState({
    total: 0, // 전체
    use: 0, // 이용방법
    error: 0, // 기능오류
    other: 0, // 기타문의
  })

  useEffect(() => {
    getUnansweredInquiry()
  }, [])

  const getUnansweredInquiry = async () => {
    setIsLoading(true)
    try {
      const param = {
        userId: user.id,
      }
      const res = await dashboardApi.getInquiryStatistics(param)
      if (res.data.code === 200) {
        setInquiryInfo({
          total: res.data.result.total_count,
          use: res.data.result.use_count,
          error: res.data.result.error_count,
          other: res.data.result.other_count,
        })
      }
    } catch (e) {
      console.log('InquiryDashboard :: getUnansweredInquiry error = ', e)
      setInquiryInfo({
        total: 0,
        use: 0,
        error: 0,
        other: 0,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const listTitle = (key) => {
    if (key === 'total') {
      return '전체'
    } else if (key === 'use') {
      return '이용방법'
    } else if (key === 'error') {
      return '기능오류'
    } else if (key === 'other') {
      return '기타문의'
    }
  }

  return (
    <Flex
      vertical
      className={`home-dashboard-component width-50 ${isLoading ? 'container-disable' : ''}`}
    >
      {isLoading && (
        <Spin
          className={'home-dashboard-loading'}
          indicator={<LoadingOutlined spin />}
          size={'large'}
        />
      )}
      <Flex align={'center'} gap={5} className={'home-dashboard-title'}>
        <h2>미답변문의</h2>
        <p className={'title-info-text'}>30일 기준</p>
      </Flex>
      <Flex
        vertical
        justify={'center'}
        gap={35}
        className={'inquiry-list-wrap'}
      >
        {Object.keys(inquiryInfo).map((key, idx) => (
          <Flex
            justify={'space-between'}
            align={'center'}
            key={`inquiry-list-${idx}`}
            className={'inquiry-list-div'}
          >
            <p>{listTitle(key)}</p>
            <Flex align={'center'} gap={7} className={'inquiry-list-count-div'}>
              <p>{inquiryInfo[key]?.toLocaleString()}</p>
              <p>건</p>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export default InquiryDashboard
