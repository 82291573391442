import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const menuState = atom({
  key: 'menu',
  default: [],
  effects_UNSTABLE: [persistAtom],
})
