import { useRecoilState } from 'recoil'
import { userState } from '../../store/user'
import { useEffect, useState } from 'react'
import { Col, Flex, Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import BenefitGoldDashboard from './BenefitGoldDashboard'
import BenefitShuFarmDashboard from './BenefitShuFarmDashboard'
import dashboardApi from '../../api/dashboard.api'
import '../../styles/home.scss'

const BenefitDashboard = () => {
  const [user, setUser] = useRecoilState(userState)
  const [isLoading, setIsLoading] = useState(true)
  const [goldInfo, setGoldInfo] = useState({
    totalCnt: 0,
    statisticsList: [
      { key: 'Lv. 01', value: 0 },
      { key: 'Lv. 02', value: 0 },
      { key: 'Lv. 03', value: 0 },
      { key: 'Lv. 04', value: 0 },
      { key: 'Lv. 05', value: 0 },
      { key: 'Lv. 06', value: 0 },
      { key: 'Lv. 07', value: 0 },
      { key: 'Lv. 08', value: 0 },
      { key: 'Lv. 09', value: 0 },
      { key: 'Lv. 10', value: 0 },
      { key: 'Lv. 11', value: 0 },
      { key: 'Lv. 12', value: 0 },
    ],
    applicationList: {
      totalCnt: 0,
      notAppliedCnt: 0,
      appliedCnt: 0,
      underwayCnt: 0,
      completeCnt: 0,
    },
  })
  const [shuFarmInfo, setShuFarmInfo] = useState({
    totalCnt: 0,
    statisticsList: [
      { key: 'Lv. 01', value: 0 },
      { key: 'Lv. 02', value: 0 },
      { key: 'Lv. 03', value: 0 },
      { key: 'Lv. 04', value: 0 },
      { key: 'Lv. 05', value: 0 },
      { key: 'Lv. 06', value: 0 },
      { key: 'Lv. 07', value: 0 },
      { key: 'Lv. 08', value: 0 },
    ],
  })
  const [shuFarmApplicationInfo, setShuFarmApplicationInfo] = useState({
    totalCnt: 0,
    // notAppliedCnt: 0,
    appliedCnt: 0,
    deliveryReadyCnt: 0,
    // underwayCnt: 0,
    deliveryShippingCnt: 0,
    completeCnt: 0,
  })

  useEffect(() => {
    getBenefitInfo()
    getShuFarmInfo()
  }, [])

  /**
   * 금나와라 뚝딱 > 보유 골드별 사용자 수 내역 조회 및 슈팜 > 레벨별 사용자 수 내역 조회
   */
  const getBenefitInfo = async () => {
    setIsLoading(true)
    try {
      const benefitParam = {
        userId: user.id,
      }
      const benefitRes =
        await dashboardApi.getUserBenefitStatistics(benefitParam)
      if (benefitRes.data.code === 200) {
        setGoldInfo({
          ...goldInfo,
          totalCnt: benefitRes.data.result.total_gold_count,
          statisticsList: benefitRes.data.result.goldStatisticsList,
        })
        setShuFarmInfo({
          ...shuFarmInfo,
          totalCnt: benefitRes.data.result.total_shufarm_count,
          statisticsList: benefitRes.data.result.shuFarmStatisticsList,
        })
      }
    } catch (e) {
      console.log('BenefitDashboard :: getBenefitInfo error = ', e)
      setGoldInfo({
        ...goldInfo,
        totalCnt: 0,
        statisticsList: [
          { key: 'Lv. 01', value: 0 },
          { key: 'Lv. 02', value: 0 },
          { key: 'Lv. 03', value: 0 },
          { key: 'Lv. 04', value: 0 },
          { key: 'Lv. 05', value: 0 },
          { key: 'Lv. 06', value: 0 },
          { key: 'Lv. 07', value: 0 },
          { key: 'Lv. 08', value: 0 },
          { key: 'Lv. 09', value: 0 },
          { key: 'Lv. 10', value: 0 },
          { key: 'Lv. 11', value: 0 },
          { key: 'Lv. 12', value: 0 },
        ],
      })
      setShuFarmInfo({
        ...shuFarmInfo,
        totalCnt: 0,
        statisticsList: [
          { key: 'Lv. 01', value: 0 },
          { key: 'Lv. 02', value: 0 },
          { key: 'Lv. 03', value: 0 },
          { key: 'Lv. 04', value: 0 },
          { key: 'Lv. 05', value: 0 },
          { key: 'Lv. 06', value: 0 },
          { key: 'Lv. 07', value: 0 },
          { key: 'Lv. 08', value: 0 },
        ],
      })
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * 슈팜 비타민 신청내역 조회
   */
  const getShuFarmInfo = async () => {
    try {
      const shuFarmRes = await dashboardApi.getShuFarmStatistics()
      if (shuFarmRes.data.code === 200) {
        setShuFarmApplicationInfo({
          ...shuFarmApplicationInfo,
          totalCnt: shuFarmRes.data.result.total_count,
          appliedCnt: shuFarmRes.data.result.applied_count,
          deliveryReadyCnt: shuFarmRes.data.result.delivery_ready_count,
          deliveryShippingCnt: shuFarmRes.data.result.delivery_shipping_count,
          completeCnt: shuFarmRes.data.result.complete_count,
        })
      }
    } catch (e) {
      console.log('BenefitDashboard :: getShuFarmInfo error = ', e)
      setShuFarmApplicationInfo({
        ...shuFarmApplicationInfo,
        totalCnt: 0,
        appliedCnt: 0,
        deliveryReadyCnt: 0,
        deliveryShippingCnt: 0,
        completeCnt: 0,
      })
    }
  }

  return (
    <Flex
      vertical
      gap={15}
      className={`home-dashboard-component ${isLoading ? 'container-disable' : ''}`}
    >
      {isLoading && (
        <Spin
          className={'home-dashboard-loading'}
          indicator={<LoadingOutlined spin />}
          size={'large'}
        />
      )}
      <Flex align={'center'} className={'home-dashboard-title'}>
        <h2>혜택 관리</h2>
      </Flex>
      <Row gutter={24}>
        <Col span={13}>
          <BenefitGoldDashboard goldInfo={goldInfo} />
        </Col>
        <Col span={11}>
          <BenefitShuFarmDashboard
            shuFarmInfo={shuFarmInfo}
            shuFarmApplicationInfo={shuFarmApplicationInfo}
            getShuFarmInfo={getShuFarmInfo}
          />
        </Col>
      </Row>
    </Flex>
  )
}

export default BenefitDashboard
