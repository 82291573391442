import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Button, Flex, Input } from 'antd'
import '../../styles/login.scss'
import loginApi from '../../api/login.api'
import { useRecoilState } from 'recoil'
import { menuState } from '../../store/menu'
import { userState } from '../../store/user'
import { tokenState } from '../../store/token'

function LoginPage() {
  const navigate = useNavigate()
  const [loginInfo, setLoginInfo] = useState({
    email: '',
    emailError: null,
    password: '',
    passwordError: null,
  })
  const [menu, setMenu] = useRecoilState(menuState)
  const [user, setUser] = useRecoilState(userState)
  const [token, setToken] = useRecoilState(tokenState)

  const handleLogin = async (e) => {
    e.preventDefault()
    const param = {
      email: loginInfo.email,
      password: loginInfo.password,
    }
    const res = await loginApi.login(param)
    if (res.data.code === 200) {
      setMenu(res.data.result.menuList)
      setUser(res.data.result.user)
      setToken(res.data.result.token)
      navigate('/')
    } else {
      let errorInfo = { ...loginInfo }
      if (!loginInfo.email || !loginInfo.email?.trim()) {
        errorInfo['emailError'] = true
      }
      if (!loginInfo.password || !loginInfo.password?.trim()) {
        errorInfo['passwordError'] = true
      }
      setLoginInfo(errorInfo)
      Swal.fire({
        customClass: { popup: 'custom-alert' },
        html: `<div class="login-alert-content">
<p>비밀번호가 일치하지 않습니다.</p>
<p class="login-alert-text">비밀번호를 분실하신 경우, CS팀에 문의해주세요.</p>
</div>`,
        confirmButtonText: '확인',
        confirmButtonColor: '#117FFA',
      })
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'email' && value?.trim()) {
      setLoginInfo({ ...loginInfo, [name]: value, emailError: null })
    } else if (name === 'password' && value?.trim()) {
      setLoginInfo({ ...loginInfo, [name]: value, passwordError: null })
    } else {
      setLoginInfo({ ...loginInfo, [name]: value })
    }
  }

  return (
    <Flex justify={'center'} align={'center'} flex={1} className={'login-div'}>
      <form onSubmit={handleLogin} className={'login-form-wrap'}>
        {/*제목-슈가코치 SALUS*/}
        <Flex justify={'center'} align={'center'} gap={8}>
          <img
            src={`${process.env.REACT_APP_HOST}/assets/sugar-logo.png`}
            className={'login-logo-img'}
          />
          <p className={'login-title'}>
            슈가코치 <span>SALUS</span>
          </p>
        </Flex>
        {/*입력창*/}
        <Flex vertical flex={1} gap={10} className={'login-input-wrap'}>
          <Flex vertical flex={1} gap={4}>
            <Input
              className={'login-input'}
              placeholder="이메일을 입력해주세요"
              name={'email'}
              value={loginInfo.email}
              onChange={handleChange}
            />
            {loginInfo.emailError && (
              <p className={'error-text'}>이메일을 입력하세요.</p>
            )}
          </Flex>
          <Flex vertical flex={1} gap={4}>
            <Input
              className={'login-input'}
              placeholder="비밀번호를 입력해주세요"
              name={'password'}
              value={loginInfo.password}
              type="password"
              onChange={handleChange}
            />
            {loginInfo.passwordError && (
              <p className={'error-text'}>비밀번호를 입력하세요.</p>
            )}
          </Flex>
        </Flex>
        {/*로그인*/}
        <Button
          htmlType={'submit'}
          type={'primary'}
          block
          className={'login-btn'}
        >
          로그인
        </Button>
      </form>
    </Flex>
  )
}

export default LoginPage
