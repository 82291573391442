import { ConfigProvider, DatePicker, Flex, Radio } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { CustomDateButtonRadioTheme } from '../../styles/CustomAntdTheme'
import '../../styles/common_component.scss'

const PeriodSearchFilter = ({
  dateType,
  setDateType,
  searchOptions,
  setSearchOptions,
  dateTypeItems,
}) => {
  const [dateTypeItemList, setDateTypeItemList] = useState(
    dateTypeItems ?? [
      { label: '오늘', value: 'today' },
      { label: '1주일', value: '1week' },
      { label: '1개월', value: '1month' },
      { label: '3개월', value: '3month' },
    ],
  )

  /**
   * handle dateType change
   */
  const handleDateTypeChange = (e) => {
    const value = e.target.value
    setDateType(value)
    switch (value) {
      case 'today':
        setSearchOptions({
          ...searchOptions,
          startDate: dayjs().startOf('day'),
          endDate: dayjs().endOf('day'),
        })
        break
      case '1week':
        if (searchOptions.endDate === null || searchOptions.endDate === '') {
          setSearchOptions({
            ...searchOptions,
            startDate: dayjs().subtract(7, 'day').startOf('day'),
            endDate: dayjs().endOf('day'),
          })
        } else {
          setSearchOptions({
            ...searchOptions,
            startDate: dayjs(searchOptions.endDate.format('YYYY/MM/DD'))
              .subtract(7, 'day')
              .startOf('day'),
          })
        }
        break
      case '1month':
        if (searchOptions.endDate === null || searchOptions.endDate === '') {
          setSearchOptions({
            ...searchOptions,
            startDate: dayjs().subtract(1, 'month').startOf('day'),
            endDate: dayjs().endOf('day'),
          })
        } else {
          setSearchOptions({
            ...searchOptions,
            startDate: dayjs(searchOptions.endDate.format('YYYY/MM/DD'))
              .subtract(1, 'month')
              .startOf('day'),
          })
        }
        break
      case '3month':
        if (searchOptions.endDate === null || searchOptions.endDate === '') {
          setSearchOptions({
            ...searchOptions,
            startDate: dayjs().subtract(3, 'month').startOf('day'),
            endDate: dayjs().endOf('day'),
          })
        } else {
          setSearchOptions({
            ...searchOptions,
            startDate: dayjs(searchOptions.endDate.format('YYYY/MM/DD'))
              .subtract(3, 'month')
              .startOf('day'),
          })
        }
        break
    }
  }

  return (
    <ConfigProvider theme={CustomDateButtonRadioTheme}>
      <Flex align={'center'} wrap gap={5}>
        <Radio.Group
          options={dateTypeItemList}
          onChange={handleDateTypeChange}
          value={dateType}
          optionType="button"
          className={`date-option-list`}
        />
        <Flex align={'center'} gap={11} className={'search-period-date-wrap'}>
          <DatePicker
            className={'search-date-input'}
            format="YYYY/M/D"
            value={searchOptions.startDate}
            allowClear={false}
            onChange={(value) => {
              setDateType('')
              setSearchOptions({ ...searchOptions, startDate: value })
            }}
          />
          <span className={`wave-text`}>~</span>
          <DatePicker
            className={'search-date-input'}
            format="YYYY/M/D"
            value={searchOptions.endDate}
            allowClear={false}
            onChange={(value) => {
              setDateType('')
              setSearchOptions({ ...searchOptions, endDate: value })
            }}
          />
        </Flex>
      </Flex>
    </ConfigProvider>
  )
}

export default PeriodSearchFilter
