import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_BACKEND_HOST}`
const mallUrl = `${process.env.REACT_APP_MALL_BACKEND_HOST}`

/**
 * 기간내 사용자 통계(방문자수/신규회원수/기존회원수) 조회
 * @param param startDate(YYYYMMDD), endDate(YYYYMMDD), userId
 */
const getUserStatistics = async (param) => {
  const res = await axios.get(`${baseUrl}/user/read/statistics`, {
    params: param,
  })
  return res
}

/**
 * 30일이내 미답변 문의 항목별 조회
 * @param param userId
 */
const getInquiryStatistics = async (param) => {
  const res = await axios.get(`${baseUrl}/inquiry/read/statistics`, {
    params: param,
  })
  return res
}

/**
 * 혜택관리 > 금나와라 뚝딱 & 슈팜 통계 내역 조회
 * - 보유골드 등급별 사용자 통계 조회
 * - 슈팜 레벨별 사용자 통계 조회
 * @param param userId
 */
const getUserBenefitStatistics = async (param) => {
  const res = await axios.get(`${baseUrl}/user/read/benefit/statistics`, {
    params: param,
  })
  return res
}

/**
 * 혜택관리 > 슈팜 비타민 신청내역 조회
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const getShuFarmStatistics = async () => {
  const res = await axios.get(
    `${mallUrl}/userOrderAddress/shuFarm/read/statistics`,
  )
  return res
}

/**
 * 유형별 슈팜 비타민 신청내역 조회
 * @param param type: 전체-total / 신청-applied / (배송)준비중-deliveryReady
 */
const getShuFarmListByType = async (param) => {
  const res = await axios.get(
    `${mallUrl}/userOrderAddress/shuFarm/admin/read/list`,
    {
      params: param,
    },
  )
  return res
}

/**
 * 슈팜 비타민 신청내역 발주 처리하기
 */
const confirmShuFarmDeliveryReady = async () => {
  const res = await axios.post(
    `${mallUrl}/userOrderAddress/edit/shuFarm/delivery/ready`,
  )
  return res
}

/**
 * 슈팜 비타민 신청내역 중 배송처리 가능한지 여부 확인하기
 * @param param idList
 */
const isPossibleUploadTrackingNumber = async (param) => {
  const res = await axios.post(
    `${mallUrl}/userOrderAddress/check/shuFarm/delivery`,
    param,
  )
  return res
}

/**
 * 슈팜 비타민 신청내역 배송정보 등록하기
 * @param param
 */
const registerShuFarmDeliveryInfo = async (param) => {
  const res = await axios.post(
    `${mallUrl}/userOrderAddress/register/shuFarm/delivery`,
    param,
  )
  return res
}

const dashboardApi = {
  getUserStatistics,
  getInquiryStatistics,
  getUserBenefitStatistics,
  getShuFarmStatistics,
  getShuFarmListByType,
  confirmShuFarmDeliveryReady,
  isPossibleUploadTrackingNumber,
  registerShuFarmDeliveryInfo,
}

export default dashboardApi
